import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import StoryBlock from "../components/titleBlockPages"
import FullWidthCTA from "../components/fullWidthCTABlock"
import Testimonials from "../components/testimonials"
import DesignDeliver from "../components/design-deliver"
import ContactForm from "../components/footerContactBlock"


const Uniforms = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPageuniformsJson.edges[0].node;

  const testimonialData = data.allTestimonialshomeJson.edges;
  const nodes = testimonialData.map((item) => { 
    return item.node;
  })
  return (
    <Layout>
      <Seo
        title="Personalised Uniforms From Front Desk To Front Line"
        description="We can provide personalised uniforms, printed or embroidered, to reflect your brand. Get a quote now >>"
      />
      <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
        bgpos={'center'}
      />
      <FullWidthCTA
      lsH3={'Your uniforms reflect your company. So, they should be of the highest quality and performance.'}
      rsBody={'On-site or back in the office, align your company branding with consistency, unite the team and spread brand awareness on the road. TKing Associates have decades of experience delivering premium uniforms, on budget and to precise specifications'}
      />
      <DesignDeliver sectionName="uniforms" />
      {/* 
      <CasestudyBlock
        sectionTitle = {'Case Study'}
        pageTitle={pageDataEdges.casestudyBlockTitle}
        pageSubTitle={pageDataEdges.casestudyBlockSubTitle}
        pageTitleBody={pageDataEdges.casestudyBlockBody}
        img={pageDataEdges.casestudyBlockImg}
      />
       */} 
      <Testimonials
        bg={"primary"}
        sectionTitleBlock={'What makes us different?'}
        sectionCopy={'More than just pens & polo shirts, we’re experts in brand expression & lasting impressions.'}
        nodes={nodes}
      />
      <StoryBlock
        sectionTitle = {'Sustainability'}
        pageTitle={pageDataEdges.storyBlockTitle}
        pageSubTitle={pageDataEdges.storyBlockSubTitle}
        pageTitleBody={pageDataEdges.storyBlockBody}
        img={pageDataEdges.storyBlockImg}
      />
      
     <ContactForm /> 
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    
    allPageuniformsJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
          storyBlockBody
          storyBlockTitle
          storyBlockSubTitle
          storyBlockImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
          casestudyBlockBody
          casestudyBlockTitle
          casestudyBlockSubTitle
          casestudyBlockImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
        }
      }
    }

    allTestimonialshomeJson {
        edges {
        node {
            name
            copy
            img {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
              
            }
        }
        }
    }
}`
export default Uniforms
